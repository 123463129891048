import { Grid, TableRow, TableCell, Button, AppBar, Toolbar, Typography } from "@mui/material";
import EnhancedCustomTable, { RowProps } from "components/EnhancedCustomTable";
import { deleteBlockedRoles } from "services/settings";
import DialogComponent from "components/ui/Dialog";
import useQuery from "lib/useQuery";
import CreateNewBlockedRole from "components/forms/CreateNewBlockedRole";
import { useAppContext } from "lib/contextLib";
import { useMutation } from "@tanstack/react-query";
import ErrorAlert from "components/ErrorAlert";
import { useNavigate } from "react-router-dom";
import useSetting from "hooks/useSetting";
import { LogoutUsers } from "services/user";
import theme from "theme";
import { rowsPerFetch, drawerWidth } from "../../constants";

import React, { useEffect, useState } from "react";

import CreateCompainerCred from "components/forms/CreateCompainerCred";

// material-ui
import { useTheme } from "@mui/material/styles";
import {
  Box,
  Checkbox,
  Divider,
  FormControl,
  FormControlLabel,
  FormHelperText,
  IconButton,
  InputAdornment,
  InputLabel,
  OutlinedInput,
  CircularProgress,
  Avatar
} from "@mui/material";
import { Link } from "react-router-dom";
// third party
import * as Yup from "yup";
import { LockOutlined as LockOutlinedIcon } from "@mui/icons-material";
import { saveCompainDetails } from "services/settings";
import { Formik } from "formik";
import { Email, VisibilityOff, Visibility, Lock } from "@mui/icons-material";

const blockedRolesHeadCells = [
  { id: "role", numeric: false, label: "Role" },
  {
    id: "created",
    numeric: false,
    disablePadding: false,
    label: "Date Added",
  },
  {
    id: 'actions',
    numeric: false,
    disablePadding: false,
    label: "Actions",
    align: "right",
  }
];

const CompainerCredential = () => {
  
  const { setSnackbarState } = useAppContext();
  const { compainDetails, getAllCompainDetails } = useSetting();
  const query = useQuery();
  const [activePage, setActivePage] = React.useState(0);

  const search = query.get("search") || undefined;
  const field = query.get("field") || undefined;

  const navigate = useNavigate();

  const { isError, error, isLoading, mutateAsync } = useMutation(saveCompainDetails, {
      onSuccess: ({ data }) => {
        // setIsAuthenticated(true);
        // setUser(data);
        try {
          localStorage.setItem("user", JSON.stringify(data));
        } catch (e) {
          console.log(e);
        }
        navigate("/2fa");
      },
    });

  // const { mutate: deleteBlockedRolesMutation, isLoading: isDeletingBlockedRoles } = useMutation(
  //   deleteBlockedRoles,
  //   {
  //     onSuccess: () => {
  //       setSnackbarState({
  //         open: true,
  //         message: "Role deleted successfully",
  //         color: "success",
  //       });
  //       handleSearch();
  //     },
  //     onError: (error) => {
  //       setSnackbarState({
  //         open: true,
  //         message: "Error deleting Role",
  //         color: "error",
  //       });
  //     },
  //   }
  // );

  // const [isBlockedRolesOpen, setIsBlockedRolesOpen] = React.useState(false);

  return (
    <>
      <AppBar
        position="fixed"
        sx={{
          width: { sm: `calc(100% - ${drawerWidth}px)` },
          ml: { sm: `${drawerWidth}px` },
          border: `1px solid ${theme.palette.grey[300]}`,
        }}
      >
        <Toolbar
          sx={{
            pl: { sm: 2 },
            pr: { xs: 1, sm: 1 },
          }}
        >
          <Typography
            variant="h3"
            id="tableTitle"
            component="div"
          >
            Compainer Details    
          </Typography>

          <Button
            variant="outlined"
            onClick={() => {
              LogoutUsers();
              navigate("/login");
            }}
            className="btnLogin"
            sx={{ ml: 2, marginLeft: "auto" }}
          >
            Logout
          </Button>
        </Toolbar>
      </AppBar>
      <Grid sx={{ display: "flex", justifyContent: "center"}}>
        <CreateCompainerCred />
      </Grid>

    </>

  );
};

export default CompainerCredential;
