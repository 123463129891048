import React from "react";
import { deleteJobs, deleteSingleJob, resetJob, getJobs, Job } from "services/jobs";
import { useMutation } from "@tanstack/react-query";
import useQuery from "lib/useQuery";
import EnhancedCustomTable, { RowProps } from "components/EnhancedCustomTable";
import {
  Grid,
  TableRow,
  TableCell,
  Chip,
  Button,
  DialogContentText,
  TextField,
  DialogActions,
  Dialog,
  DialogContent,
  DialogTitle,
  CircularProgress,
  Box
} from "@mui/material";
import ErrorAlert from "components/ErrorAlert";
import DialogComponent from "components/ui/Dialog";
import { daysOfWeek, rowsPerFetch } from "../constants";
import CreateNewJob from "components/forms/CreateNewJob";
import { useAppContext } from "lib/contextLib";
import { useNavigate } from "react-router-dom";
import moment from "moment";
import useJobs from "hooks/useJobs";
import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';
import VisibilityIcon from '@mui/icons-material/Visibility';
import DeleteIcon from '@mui/icons-material/Delete';
import RestartAltIcon from '@mui/icons-material/RestartAlt';

const jobsColumns = [
  {
    id: "id",
    label: "ID",
  },
  {
    id: "name",
    label: "Name",
  },
  {
    id: "scrubbedProfileForEmail",
    numeric: true,
    disablePadding: false,
    label: "Scrubed Profile for email",
    width: "90px",
    align: "center",
  },
  {
    id: "status",
    numeric: false,
    disablePadding: false,
    label: "Status",
    width: "90px",
    align: "center",
  },
  {
    id: "recurring",
    numeric: false,
    disablePadding: false,
    label: "Recurring",
    width: "90px",
    align: "center",
  },
  {
    align: "center",
    id: "mv_rejects",
    numeric: false,
    disablePadding: false,
    label: "MV Rejects",
    width: "100px",
  },
  {
    align: "center",
    id: "oe_rejects",
    numeric: false,
    disablePadding: false,
    label: "EO Rejects",
    width: "100px",
  },
  {
    align: "center",
    id: "job_details_count",
    numeric: false,
    disablePadding: false,
    label: "Campaigner / Mailwizz Valid",
    width: "100px",
  },
  {
    id: "days_running",
    numeric: false,
    disablePadding: false,
    label: "Days Running",
    minWidth: "152px",
    width: "152px",
    // align: "center",
  },
  {
    id: "date_started",
    numeric: true,
    //align: "center",
    disablePadding: false,
    label: "Date <br /> Started",
    width: "100px",
  },
  {
    id: "date_completed",
    numeric: true,
    //align: "center",
    disablePadding: false,
    label: "Date  <br /> Completed",
    width: "100px",
  },
  {
    id: "actions",
    end: true,
    disablePadding: false,
    align: "center",
    label: "Actions",
    width: "80px",
  },
];

const Jobs = () => {
  const { jobs, getAllJobs } = useJobs();
  const { setSnackbarState } = useAppContext();

  const { isLoading: isDeleteJobLoading, mutate } = useMutation(deleteJobs, {
    onSuccess: () => {
      setSnackbarState({
        open: true,
        message: "Job deleted successfully",
        color: "success",
      });
      handleSearch();
    },
    onError: () => {
      setSnackbarState({
        open: true,
        message: "Error deleting job",
        color: "error",
      });
    },
  });

  const query = useQuery();
  const [activePage, setActivePage] = React.useState(0);
  const [jobsToDel, setJobsToDel] = React.useState<any[]>([]);
  const [isDeleteDialogOpen, setIsDeleteDialogOpen] = React.useState(false);
  const [deleteInputVal, setDeleteInputVal] = React.useState("");

  const [open, setOpen] = React.useState(false);
  const [resetOpen, setResetOpen] = React.useState(false);
  const [jobId, setJobId] = React.useState("");
  const [secret, setSecretKey] = React.useState("");
  const [isDeleting, setIsDeleting] = React.useState(false);
  const [isResetting, setIsResetting] = React.useState(false);

// delete job mutation
const { isLoading: isDeleteSingleJobLoading, mutate: deleteSingleJobMutate } = 
  useMutation(({ jobId, secret }: { jobId: string; secret: string }) => deleteSingleJob(jobId, secret), {
    onSuccess: () => {
      setSnackbarState({
        open: true,
        message: "Job deleted successfully",
        color: "success",
      });
      setIsDeleting(false)
      handleSearch();
      setOpen(false)
      setSecretKey("");
      setJobId("");
    },
    onError: (error: any) => {
      setSnackbarState({
        open: true,
        message: error.response.data.message || "Error deleting Job",
        color: "error",
      });
      setSecretKey("");
      setIsDeleting(false)
    },
  });

// reset job mutation
const { isLoading: isResetJobLoading, mutate: resetJobMutate } = 
  useMutation(({ jobId, secret }: { jobId: string; secret: string }) => resetJob(jobId, secret), {
    onSuccess: () => {
      setSnackbarState({
        open: true,
        message: "Job restarted successfully",
        color: "success",
      });
      setIsResetting(false)
      handleSearch();
      setResetOpen(false)
      setSecretKey("");
      setJobId("");
    },
    onError: (error: any) => {
      setSnackbarState({
        open: true,
        message: error.response.data.message || "Error restarting job",
        color: "error",
      });
      setSecretKey("");
      setResetOpen(false);
      setIsResetting(false)
    },
  });
  
  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleOk = () => {
    setIsDeleting(true)
    deleteSingleJobMutate({jobId, secret});
  }

  const handleClose = () => {
    setOpen(false);
  };

  const handleOkReset = () => {
    setIsResetting(true);
    resetJobMutate({jobId, secret})
  }

  const handleResetClose = () => {
    setResetOpen(false);
  };

  const deleteSingleJobAction = (e: any, id: string ) => {
    e.stopPropagation();
    setJobId(id);
    setOpen(true);
  };

  const onRestartJob = (e: any, id: string) => {
    e.stopPropagation();
    setJobId(id);
    setResetOpen(true);
  }

  const search = query.get("search") || undefined;
  const field = query.get("field") || undefined;

  const incrementPage = (value: number) => {
    setActivePage(value);
  };
  React.useEffect(() => {
    if (search || field) {
      setActivePage(0)
      handleSearch({ page: 0 });
    }
  }, [search, field])

  React.useEffect(() => {
    if ((activePage !== 0 && activePage > (jobs?.lodedPageData as any) && jobs?.jobs?.length !== jobs?.total) ||
      (!jobs?.jobs?.length || jobs?.isFiltered)) {
      handleSearch()
    }
  }, [activePage]);

  const handleSearch = ({ ...query } = {}) => {
    getAllJobs({ page: activePage, limit: rowsPerFetch, search, field, ...query })
  }

  const [createJobDialogOpen, setCreateJobDialogOpen] = React.useState(false);
  const onJobCreateClick = () => {
    setCreateJobDialogOpen(true);
  };
  const onJobsDelete = (ids: string[]) => {
    setJobsToDel(ids);
    setIsDeleteDialogOpen(true);
  };
  const handleSuccess = () => {
    setCreateJobDialogOpen(false);
    setSnackbarState({
      open: true,
      message: "Job created successfully",
      color: "success",
    });
    handleSearch();
  };
  const handleJobsDeleteConfirm = () => {
    mutate(jobsToDel);
    setIsDeleteDialogOpen(false);
  };

  return (
    <Grid container>
      {jobs?.error && <ErrorAlert />}
      <EnhancedCustomTable
        onAddClick={onJobCreateClick}
        onDeleteClick={onJobsDelete}
        onSingleJobDelete={deleteSingleJobAction}
        onRestartJob={onRestartJob}
        Row={JobRow}
        headCells={jobsColumns}
        rows={jobs?.jobs ?? []}
        incrementFetch={incrementPage}
        totalCountParent={jobs?.total}
        pageParent={activePage}
        fieldsToSearch={["status", "Scrubed profile", "Recurring"]}
        isLoading={(jobs?.isLoading || isDeleteJobLoading) ?? false}
        selectionKey="id"
        title="Jobs"
      />

    {/* Single Delete Job Popup */}

      <Dialog
        open={open}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        onClose={(event, reason) => {
          if (reason !== 'backdropClick') {
            handleClose();
          }
        }}
      >
      <DialogTitle id="alert-dialog-title">
        {"Are You Sure?"}
      </DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          Once completed, it cannot be reversed.
        </DialogContentText>
        <TextField
          autoFocus
          required
          margin="dense"
          id="secret"
          name="secret"
          label="Secret Key"
          type="text"
          onChange={(e: any) => setSecretKey(e.target.value)}
          fullWidth
          variant="standard"
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={() => {handleClose()}}>Disagree</Button>
        <Button onClick={handleOk} autoFocus disabled={secret === ''}>
          {
            isDeleting ? <CircularProgress size={20} /> : "Agree"
          }
        </Button>
      </DialogActions>
      </Dialog>

      {/* Reset Job Dailog */}
      <Dialog
        open={resetOpen}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        onClose={(event, reason) => {
          if (reason !== 'backdropClick') {
            handleResetClose();
          }
        }}
      >
      <DialogTitle id="alert-dialog-title">
        {"Are You Sure?"}
      </DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          Once completed, it cannot be reversed.
        </DialogContentText>
        <TextField
          autoFocus
          required
          margin="dense"
          id="secret"
          name="secret"
          label="Secret Key"
          type="text"
          onChange={(e: any) => setSecretKey(e.target.value)}
          fullWidth
          variant="standard"
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={() => {handleResetClose()}}>Disagree</Button>
        <Button onClick={handleOkReset} autoFocus disabled={secret === ''}>
          {
            isResetting ? <CircularProgress size={20} /> : "Agree"
          }
        </Button>
      </DialogActions>
      </Dialog>
      

    {/* Single Delete Job Popup */}

      <DialogComponent
        isDialogOpen={isDeleteDialogOpen}
        closeDialog={() => setIsDeleteDialogOpen(false)}
        title="Are you sure you want to delete the selected jobs?"
      >
        <DialogContentText>
          Please type the word &quot;delete&quot; to confirm
        </DialogContentText>
        <TextField
          autoFocus
          margin="dense"
          id="name"
          label="Type delete"
          type="text"
          fullWidth
          value={deleteInputVal}
          onChange={(e) => setDeleteInputVal(e.target.value)}
          variant="standard"
        />

        <DialogActions sx={{ marginTop: 2 }}>
          <Button
            onClick={() => setIsDeleteDialogOpen(false)}
            variant="contained"
            color="primary"
          >
            Cancel
          </Button>
          <Button
            onClick={handleJobsDeleteConfirm}
            color="error"
            variant="contained"
            disabled={
              isDeleteJobLoading || deleteInputVal.toLowerCase() !== "delete"
            }
          >
            Delete
          </Button>
        </DialogActions>
      </DialogComponent>

      <DialogComponent
        dialogProps={{
          open: createJobDialogOpen,
          fullScreen: true,
        }}
        isDialogOpen={createJobDialogOpen}
        closeDialog={() => setCreateJobDialogOpen(false)}
        title="Create Job"
      >
        <CreateNewJob onSuccess={handleSuccess} />
      </DialogComponent>
    </Grid>
  );
};

function JobRow({
  selectionKey,
  isItemSelected,
  labelId,
  handleClick,
  row,
  onSingleJobDelete,
  onRestartJob
}: RowProps) {

  const navigate = useNavigate();

  const handleViewClick = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.stopPropagation();
    navigate(`/jobs/${row.id}`);
  };

  const total =
    parseInt(row.mv_rejects) +
    parseInt(row.oe_rejects) +
    parseInt(row.job_details_count);

  return (
    <>
    <TableRow
      hover
      onClick={(event) => handleClick(event, (row as any)[selectionKey])}
      role="checkbox"
      aria-checked={isItemSelected}
      tabIndex={-1}
      key={row.id}
      selected={isItemSelected}
    >
      <TableCell padding={"none"} align="left"> {row.id} </TableCell>
      <TableCell padding={"none"} align="left"> {`${row.name} ${row.sequence_number > 0 ? "#"+row.sequence_number : "" }`} </TableCell>
      <TableCell padding={"none"} align="center">
        {new Intl.NumberFormat().format(row.scrubbedProfileForEmail)}
      </TableCell>
      <TableCell padding={"none"} align="center">{row.status}</TableCell>
      <TableCell padding={"none"} align="center">
        {row.recurring ? (
          <Chip
            sx={{ color: "white" }}
            size="small"
            label="Yes"
            color="success"
          />
        ) : (
          <Chip
            sx={{ color: "white" }}
            size="small"
            label="No"
            color="error"
          />
        )}
      </TableCell>
      <TableCell  padding={"none"} align="center" >
        {new Intl.NumberFormat().format(parseInt(row.mv_rejects))}
        <br/>
        (
        {new Intl.NumberFormat().format(
          parseFloat(((parseInt(row.mv_rejects) / total) * 100).toFixed(2)) || 0
        )}
        %)
      </TableCell>
      <TableCell padding={"none"} align="center" >
        {new Intl.NumberFormat().format(parseInt(row.oe_rejects))}
        <br/>
        (
        {new Intl.NumberFormat().format(
          parseFloat(((parseInt(row.oe_rejects) / total) * 100).toFixed(2)) || 0
        )}
        %)
      </TableCell>
      <TableCell padding={"none"} align="center" >
        {new Intl.NumberFormat().format(parseInt(row.job_details_count))}
        <br/>
        (
        {new Intl.NumberFormat().format(
          parseFloat(((parseInt(row.job_details_count) / total) * 100).toFixed(2)) || 0
        )}
        %)
      </TableCell>
      <TableCell padding={"none"} align="left">
        <Box sx={{display: "flex", flexWrap: "wrap", gap: "5px", justifyContent: "start", alignItems: "center" }}>
          {daysOfWeek
            .filter((day) => row[day])
            .map((day) => (
              <Box key={day}>
                <Chip
                  className="dayChip"
                  sx={{textTransform: "capitalize"}}
                  size="small"
                  label={day.substring(0, 2)}
                  color="primary"
                />
              </Box>
            ))}
        </Box>
      </TableCell>
      <TableCell  padding={"none"} >
        {row?.start_date ?  
        <>
          {moment(row.start_date).format("MM/DD/YY")}
          <br/>
          {moment(row.start_date).format("hh:mm a")}
        </>
        : "-" }
      </TableCell>
      <TableCell padding={"none"} >
        {row.date_completed
          ? 
          <>
            {moment(row.date_completed).format("MM/DD/YY")}
            <br/>
            {moment(row.date_completed).format("hh:mm a")}
          </>
          : "-"}
      </TableCell>
      <TableCell padding={"none"} >
        <Box sx={{ display: "flex", gap: "5px" }}>
          <Button sx={{padding: "0", minWidth: "inherit"}} onClick={handleViewClick}>
            <VisibilityIcon />
          </Button>
          <Button sx={{padding: "0", minWidth: "inherit"}} onClick={(e: any) => {onSingleJobDelete(e, row.id)}} color="error">
            <DeleteIcon />
          </Button>
          {
            row.status === "FAILED" && parseInt(row.mv_rejects) <= 0 && parseInt(row.oe_rejects) <= 0 && parseInt(row.job_details_count) <= 0 ?
            <Button sx={{padding: "0", minWidth: "inherit"}} onClick={(e: any) => {onRestartJob(e, row.id)}} color="warning">
              <RestartAltIcon />
            </Button>
            : ''
          }
        </Box>
      </TableCell>
    </TableRow>
    </>
  );
}

export default Jobs;
function setSnackbarState(arg0: { open: boolean; message: string; color: string; }) {
  throw new Error("Function not implemented.");
}

