import { Grid, TableRow, TableCell, Button, AppBar, Toolbar, Typography } from "@mui/material";
import EnhancedCustomTable, { RowProps } from "components/EnhancedCustomTable";
import { deleteBlockedRoles } from "services/settings";
import DialogComponent from "components/ui/Dialog";
import useQuery from "lib/useQuery";
import CreateNewBlockedRole from "components/forms/CreateNewBlockedRole";
import { useAppContext } from "lib/contextLib";
import { useMutation } from "@tanstack/react-query";
import ErrorAlert from "components/ErrorAlert";
import { useNavigate } from "react-router-dom";
import useSetting from "hooks/useSetting";
import { LogoutUsers } from "services/user";
import theme from "theme";
import { rowsPerFetch, drawerWidth } from "../../constants";

import React, { useEffect, useState } from "react";

// material-ui
import { useTheme } from "@mui/material/styles";
import {
  Box,
  Checkbox,
  Divider,
  FormControl,
  FormControlLabel,
  FormHelperText,
  IconButton,
  InputAdornment,
  InputLabel,
  OutlinedInput,
  CircularProgress,
  Avatar
} from "@mui/material";
import { Link } from "react-router-dom";
// third party
import * as Yup from "yup";
import { LockOutlined as LockOutlinedIcon } from "@mui/icons-material";
import { saveMailwizzDetails } from "services/settings";
import { Formik } from "formik";
import { Key, Link as DisplayLink } from "@mui/icons-material";

export const statuses = ['Off', 'Finished', 'Pending', 'Failed', 'Running' ]

const CreateMailwizzCred = () => {

  const { setSnackbarState } = useAppContext();
  const { mailwizzDetails, getAllMailwizzDetails } = useSetting();
  
  useEffect(() => {
    getAllMailwizzDetails();
  }, [])

  const { isError, error, isLoading, mutateAsync } = useMutation(saveMailwizzDetails, {
      onSuccess: ({ data }) => {
        setSnackbarState({
          open: true,
          message: "Data Saved Successfully",
          color: "success",
        });
      },
    });

  return (
    <Box width={"60%"}>
        <Formik
          initialValues={{
            id: mailwizzDetails?.id,
            api_key: mailwizzDetails.api_key,
            transmit_subscriber_link: mailwizzDetails.transmit_subscriber_link
          }}
          enableReinitialize={true} 
          validationSchema={Yup.object().shape({
              api_key: Yup.string().required("api_key is required"),
              transmit_subscriber_link: Yup.string().required("transmit_subscriber_link is required")
          })}
          onSubmit={(
            { api_key, transmit_subscriber_link, id },
            { setSubmitting, setErrors, setStatus }
          ) => {
            mutateAsync({ api_key, transmit_subscriber_link, id });
          }}
        >
          {({
            errors,
            handleBlur,
            handleChange,
            handleSubmit,
            isSubmitting,
            touched,
            values
          }) => (
            <form noValidate onSubmit={handleSubmit}>
              <Grid container direction="column" gap={2}>
                <Grid
                  item
                  container
                  justifyContent={"center"}
                  alignItems="center"
                  direction="column"
                >
                  <Avatar sx={{ m: 1, bgcolor: "primary.main" }}>
                    <LockOutlinedIcon />
                  </Avatar>
                  <Typography component="h1" variant="h5">
                    Mailwizz Credential
                  </Typography>
                </Grid>

                <Grid item container direction="column">
                  <Grid item>
                    <InputLabel
                      htmlFor="outlined-adornment-email-login"
                      sx={{ color: "grey.600" }}
                    >
                      Api Key
                    </InputLabel>
                  </Grid>

                <Grid item>
                  <FormControl
                    fullWidth
                    error={Boolean(touched.api_key && errors.api_key)}
                    sx={{ ...theme.customInput }}
                  >
                    <OutlinedInput
                      startAdornment={
                        <InputAdornment position="start">
                          <Key sx={{ color: "grey.500" }} />
                        </InputAdornment>
                      }
                      id="outlined-adornment-apiid"
                      type="text"
                      value={values.api_key}
                      name="api_key"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      inputProps={{}}
                    />
                  </FormControl>
                  {touched.api_key && errors.api_key && (
                    <FormHelperText
                      error
                      id="standard-weight-helper-text-api_key"
                    >
                      {errors.api_key}
                    </FormHelperText>
                  )}
                </Grid>

                </Grid>

                <Grid item container direction="column">
                  <Grid item>
                    <InputLabel
                      htmlFor="outlined-adornment-email-login"
                      sx={{ color: "grey.600" }}
                    >
                      Transmit Subscriber Link
                    </InputLabel>
                  </Grid>
                  <Grid item>
                    <FormControl
                      fullWidth
                      error={Boolean(touched.transmit_subscriber_link && errors.transmit_subscriber_link)}
                      sx={{ ...theme.customInput }}
                    >
                      <OutlinedInput
                        startAdornment={
                          <InputAdornment position="start">
                            <DisplayLink sx={{ color: "grey.500" }} />
                          </InputAdornment>
                        }
                        type="text"
                        id="outlined-adornment-transmit_subscriber_link"
                        value={values.transmit_subscriber_link}
                        name="transmit_subscriber_link"
                        onBlur={handleBlur}
                        onChange={handleChange}
                        inputProps={{}}
                      />
                    </FormControl>
                    {touched.transmit_subscriber_link && errors.transmit_subscriber_link && (
                      <FormHelperText
                        error
                        id="standard-weight-helper-text-transmit_subscriber_link"
                      >
                        {errors.transmit_subscriber_link}
                      </FormHelperText>
                    )}
                  </Grid>
                </Grid>

                <Grid
                  item
                  container
                  justifyContent={"space-between"}
                  alignItems={"center"}
                >
                </Grid>
          
                <Grid item mt={2} container>
                  <Button
                    disableElevation
                    disabled={isLoading}
                    fullWidth
                    size="large"
                    type="submit"
                    variant="contained"
                    color="primary"
                  >
                    {isLoading ? <CircularProgress /> : "Save"}
                  </Button>
                </Grid>
              </Grid>
            </form>
          )}
        </Formik>
    </Box>
    )

}

export default CreateMailwizzCred;
