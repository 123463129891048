import { useQuery } from "@tanstack/react-query";
import axiosService from "lib/axios";

const route = "/settings";
export async function getBlackList(
  page: number,
  limit: number,
  search?: string,
  field?: string
) {
  const { data } = await axiosService.get(route + "/blacklist", {
    params: { page, limit, search, field },
  });
  if (data.count) {
    window.localStorage.setItem("totalCountBlacklist", data.count);
  }
  return {
    blacklists: data.blacklists,
    page: data.page,
    limit: data.limit,
    totalCount:
      data.count || window.localStorage.getItem("totalCountBlacklist"),
  };
}

export async function getAllowedTLDs(
  page: number,
  limit: number,
  search?: string,
  field?: string
) {
  const { data } = await axiosService.get(route + "/allowedTLDs", {
    params: { page, limit, search, field },
  });
  if (data.count) {
    window.localStorage.setItem("totalCountAllowedTLDs", data.count);
  }
  console.log(data);
  return {
    allowedTLDs: data.allowedTlds,
    page: data.page,
    limit: data.limit,
    totalCount:
      data.count || window.localStorage.getItem("totalCountAllowedTLDs"),
  };
}

async function getBlockedRole(id: string) {
  const { data } = await axiosService.get(route + "/blockedRoles" + '/' + id);
  return data
}

export function useGetBlockedRole(id: string) {
  return useQuery(["blockedRole", id], () => getBlockedRole(id));
}

export function editBlockedRole({ id, role }: any) {
  return axiosService.put(route + "/blockedRoles" + '/' + id, { role });
}

export function deleteBlackLists(ids: string[]) {
  return axiosService.delete(route + "/blacklist", { data: { ids } });
}
export function useGetAllowedTLDs() {
  return useQuery(["allowedTLDs"], () => getAllowedTLDs);
}

export function addToList({ email, emails, reason }: { email?: string; emails?: string[], reason: string }) {
  return axiosService.post(route + "/blacklist", { email, emails, reason });
}

export function addToTLDs(tld: string) {
  return axiosService.post(route + "/allowedTLDs", { tld });
}

export function saveCompainDetails(setting: { api_key: string, transmit_subscriber_link: string, avalible_list_link: string, id: number }) {
  return axiosService.post(route + '/add-compainer-details', { id: setting.id, api_key: setting.api_key, transmit_subscriber_link: setting.transmit_subscriber_link, avalible_list_link: setting.avalible_list_link });
}

export function saveMailwizzDetails(setting: { api_key: string, transmit_subscriber_link: string, id: number }) {
  return axiosService.post(route + '/add-mailwizz-details', { id: setting.id, api_key: setting.api_key, transmit_subscriber_link: setting.transmit_subscriber_link });
}

export function deleteAllowedTLDs(ids: string[]) {
  return axiosService.delete(route + "/allowedTLDs", { data: { ids } });
}
export async function getBlockedRoles(
  page: number,
  limit: number,
  search?: string,
  field?: string
) {
  const { data } = await axiosService.get(route + "/blockedRoles", {
    params: { page, limit, search, field },
  });
  if (data.count) {
    window.localStorage.setItem("totalCountBlockedRoles", data.count);
  }
  return {
    blockedRoles: data.blockedRoles,
    page: data.page,
    limit,
    totalCount:
      data.count || window.localStorage.getItem("totalCountBlockedRoles"),
  };
}

export function addToBlockedRoles({ role, roles }: { role?: string; roles?: string[] }) {
  return axiosService.post(route + "/blockedRoles", { role, roles });
}
export function deleteBlockedRoles(ids: string[]) {
  return axiosService.delete(route + "/blockedRoles", { data: { ids } });
}

export async function getBlockedDomains(
  page: number,
  limit: number,
  search?: string,
  field?: string
) {
  const { data } = await axiosService.get(route + "/blockedDomains", {
    params: { page, limit, search, field },
  });
  if (data.count) {
    window.localStorage.setItem("totalCountBlockedDomains", data.count);
  }
  return {
    blockedDomains: data.blockedDomains,
    page: data.page,
    limit: data.limit,
    totalCount:
      data.count || window.localStorage.getItem("totalCountBlockedDomains"),
  };
}

export function useGetBlockedDomains() {
  return useQuery(["blockedDomains"], () => getBlockedDomains);
}

export function addToBlockedDomains({ domain, domains }: { domain?: string, domains?: string[] }) {
  return axiosService.post(route + "/blockedDomains", { domain, domains });
}
export function deleteBlockedDomains(ids: string[]) {
  return axiosService.delete(route + "/blockedDomains", { data: { ids } });
}

export async function getAuditLogs(page: number, limit: number, search?: string, field?: string) {
  const { data } = await axiosService.get(route + "/audit-logs", {
    params: { page, limit, search, field },
  });
  return data;
}

async function getBlackListItem(id: string) {
  const { data } = await axiosService.get(route + "/blacklist" + '/' + id);
  return data
}
export function useGetBlacklistItem(id: string) {
  return useQuery(["blacklist", id], () => getBlackListItem(id));
}

export function editBlacklistItem({ id, email }: any) {
  return axiosService.put(route + "/blacklist" + '/' + id, { email });
}

async function getBlockedDomain(id: string) {
  const { data } = await axiosService.get(route + "/blockedDomains" + '/' + id);
  return data
}

export function useGetBlockedDomain(id: string) {
  return useQuery(["blockedDomain", id], () => getBlockedDomain(id));
}

export function editBlockedDomain({ id, domain }: any) {
  return axiosService.put(route + "/blockedDomains" + '/' + id, { domain });
}

async function getAllowedTLD(id: string) {
  const { data } = await axiosService.get(route + "/allowedTLDs" + '/' + id);
  return data
}

export function useGetAllowedTLD(id: string) {
  return useQuery(["allowedTLD", id], () => getAllowedTLD(id));
}

export function editAllowedTLD({ id, tld }: any) {
  return axiosService.put(route + "/allowedTLDs" + '/' + id, { tld });
}

