import { Grid, TableRow, TableCell, Button, AppBar, Toolbar, Typography } from "@mui/material";
import EnhancedCustomTable, { RowProps } from "components/EnhancedCustomTable";
import { deleteBlockedRoles } from "services/settings";
import DialogComponent from "components/ui/Dialog";
import useQuery from "lib/useQuery";
import CreateNewBlockedRole from "components/forms/CreateNewBlockedRole";
import { useAppContext } from "lib/contextLib";
import { useMutation } from "@tanstack/react-query";
import ErrorAlert from "components/ErrorAlert";
import { useNavigate } from "react-router-dom";
import useSetting from "hooks/useSetting";
import { LogoutUsers } from "services/user";
import theme from "theme";
import { rowsPerFetch, drawerWidth } from "../../constants";

import React, { useEffect, useState } from "react";

import CreateMailwizzCred from "components/forms/CreateMailwizzCred";

// material-ui
import { useTheme } from "@mui/material/styles";
import {
  Box,
  Checkbox,
  Divider,
  FormControl,
  FormControlLabel,
  FormHelperText,
  IconButton,
  InputAdornment,
  InputLabel,
  OutlinedInput,
  CircularProgress,
  Avatar
} from "@mui/material";
import { Link } from "react-router-dom";
// third party
import * as Yup from "yup";
import { LockOutlined as LockOutlinedIcon } from "@mui/icons-material";
import { saveCompainDetails } from "services/settings";
import { Formik } from "formik";
import { Email, VisibilityOff, Visibility, Lock } from "@mui/icons-material";

const blockedRolesHeadCells = [
  { id: "role", numeric: false, label: "Role" },
  {
    id: "created",
    numeric: false,
    disablePadding: false,
    label: "Date Added",
  },
  {
    id: 'actions',
    numeric: false,
    disablePadding: false,
    label: "Actions",
    align: "right",
  }
];

const MailwizzCredential = () => {
  
  const navigate = useNavigate();

  return (
    <>
      <AppBar
        position="fixed"
        sx={{
          width: { sm: `calc(100% - ${drawerWidth}px)` },
          ml: { sm: `${drawerWidth}px` },
          border: `1px solid ${theme.palette.grey[300]}`,
        }}
      >
        <Toolbar
          sx={{
            pl: { sm: 2 },
            pr: { xs: 1, sm: 1 },
          }}
        >
          <Typography
            variant="h3"
            id="tableTitle"
            component="div"
          >
            Mailwizz Details    
          </Typography>

          <Button
            variant="outlined"
            onClick={() => {
              LogoutUsers();
              navigate("/login");
            }}
            className="btnLogin"
            sx={{ ml: 2, marginLeft: "auto" }}
          >
            Logout
          </Button>
        </Toolbar>
      </AppBar>
      <Grid sx={{ display: "flex", justifyContent: "center"}}>
        <CreateMailwizzCred />
      </Grid>

    </>

  );
};

export default MailwizzCredential;
