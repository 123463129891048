import { createAsyncThunk } from "@reduxjs/toolkit";
import axiosService from "lib/axios";

export const fetchBlackListsAction = createAsyncThunk<undefined, { page: number, limit: number, search?: string, field?: string }>(
    "settings/blacklist",
    async (arg, thinkAPI) => {
        const { data, status } = await axiosService.get("settings/blacklist", { params: arg });

        if (data.count) {
            window.localStorage.setItem("totalCountBlacklist", data.count);
        }

        if (status !== 200) return thinkAPI.rejectWithValue(new Error(data.message || "Something is wrong here"));
        return thinkAPI.fulfillWithValue({ ...data, arg });
    },
);

export const fetchBlockedDomainsAction = createAsyncThunk<undefined, { page: number, limit: number, search?: string, field?: string }>(
    "settings/blockedDomains",
    async (arg, thinkAPI) => {
        const { data, status } = await axiosService.get("settings/blockedDomains", { params: arg });
        if (data.count) {
            window.localStorage.setItem("totalCountBlockedDomains", data.count);
        }
        if (status !== 200) return thinkAPI.rejectWithValue(new Error(data.message || "Something is wrong here"));
        return thinkAPI.fulfillWithValue({ ...data, arg });
    },
);

export const fetchBlockedRolesAction = createAsyncThunk<undefined, { page: number, limit: number, search?: string, field?: string }>(
    "settings/blockedRoles",
    async (arg, thinkAPI) => {
        const { data, status } = await axiosService.get("settings/blockedRoles", { params: arg });
        if (data.count) {
            window.localStorage.setItem("totalCountBlockedRoles", data.count);
        }
        if (status !== 200) return thinkAPI.rejectWithValue(new Error(data.message || "Something is wrong here"));
        return thinkAPI.fulfillWithValue({ ...data, arg });
    },
);

export const fetchAllowedTldsAction = createAsyncThunk<undefined, { page: number, limit: number, search?: string, field?: string }>(
    "settings/allowedTlds",
    async (arg, thinkAPI) => {
        const { data, status } = await axiosService.get("settings/allowedTLDs", { params: arg });
        if (data.count) {
            window.localStorage.setItem("totalCountAllowedTLDs", data.count);
        }
        if (status !== 200) return thinkAPI.rejectWithValue(new Error(data.message || "Something is wrong here"));
        return thinkAPI.fulfillWithValue({ ...data, arg });
    },
);

export const fetchGetCompainer = createAsyncThunk<any>(
    "settings/compainerDetails",
    async (arg, thinkAPI) => {
        const { data, status } = await axiosService.get("settings/compainer-details");
        if (status !== 200) return thinkAPI.rejectWithValue(new Error(data.message || "Something is wrong here"));
        return thinkAPI.fulfillWithValue({ ...data, arg });
    },
);

export const fetchGetMailwizz = createAsyncThunk<any>(
    "settings/mailwizzDetails",
    async (arg, thinkAPI) => {
        const { data, status } = await axiosService.get("settings/mailwizz-details");
        if (status !== 200) return thinkAPI.rejectWithValue(new Error(data.message || "Something is wrong here"));
        return thinkAPI.fulfillWithValue({ ...data, arg });
    },
);

export const fetchAllUsersAction = createAsyncThunk<undefined, { page: number, limit: number, search?: string, field?: string }>(
    "settings/users",
    async (arg, thinkAPI) => {
        const { data, status } = await axiosService.get("users", { params: arg });
        if (data.count) {
            window.localStorage.setItem("totalCountUsers", data.count);
        }
        if (status !== 200) return thinkAPI.rejectWithValue(new Error(data.message || "Something is wrong here"));
        return thinkAPI.fulfillWithValue({ ...data, arg });
    },
);
