import { createSlice, SliceCaseReducers } from "@reduxjs/toolkit";
import { fetchJobAction } from "store/actions/jobs.action";

export type Job = {
    created: string,
    created_by: number,
    scrubbedProfileForEmail: number,
    date_completed: null | string,
    days_last_mailed: null | string,
    exclude_ISP: null | string,
    friday: boolean,
    id: number,
    include_ISP: null | string,
    job_details_count: string,
    list_ids: number[],
    mailwizz_campaign: null | string,
    mailwizz_list: string
    monday: boolean,
    mv_rejects: number,
    name: number,
    notes: null | string,
    oe_rejects: string,
    recurring: boolean,
    saturday: boolean,
    status: string,
    sunday: boolean,
    thursday: boolean,
    tuesday: boolean,
    wednesday: boolean,
};

export type JobsState = {
    jobs: {
        total?: number;
        isFiltered?: boolean;
        jobs?: Job[];
        isLoading?: boolean;
        error?: boolean;
        lodedPageData?: number;
    };
};

export const jobsSlice = createSlice<JobsState, SliceCaseReducers<JobsState>, string>({
    name: "jobs",
    initialState: {
        jobs: {
            total: parseInt(localStorage.getItem('totalCountJobs') ?? '0') || 0,
            isFiltered: false,
            jobs: [],
            isLoading: true,
            error: false,
            lodedPageData: 0
        },
    },
    reducers: {},
    extraReducers: (builder) => {
        // fetchJobListsAction
        builder.addCase(fetchJobAction.pending, (state) => {
            state.jobs = {
                ...state.jobs,
                isLoading: true
            }
        });
        builder.addCase(fetchJobAction.fulfilled, (state, { payload }: any) => {
            const { arg } = payload;

            state.jobs = {
                isLoading: false,
                isFiltered: arg.search && arg.field ? true : false,
                total: parseInt(payload.count ?? 0),
                jobs: (arg.search && arg.field && arg.page !== 0) ? [...state.jobs?.jobs as any, ...payload.jobs] : payload.jobs,
                lodedPageData: arg.page
            }
        });
        builder.addCase(fetchJobAction.rejected, (state) => {
            state.jobs = {
                ...state.jobs,
                isLoading: false,
                error: true
            }
        });
    },
});

export const { setIsToast } = jobsSlice.actions;

export default jobsSlice.reducer;
